 
 <template>
  <v-container>
      <v-btn class="ma-2" tile large color="green darken-2" @click="printform()" icon>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    <v-row>
      <v-col class="pb-2" cols="12">
        <v-form ref="form" @submit.prevent="validateBeforeSubmit">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col class="wrap" cols="12">
                  <v-text-field
                    v-model="vm.title"
                    label="Title"
                    v-validate="'required'"
                    data-vv-as="title"
                    name="title"
                    :error-messages="errors.collect('title')"
                  ></v-text-field>
                </v-col>
                <v-col class="wrap" cols="12">
                  <v-textarea
                    v-model="vm.description"
                    label="Description"
                    filled
                    rows="1"
                    v-validate="'required'"
                    data-vv-as="description"
                    name="description"
                    :error-messages="errors.collect('description')"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" type="submit">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-2" cols="12">
        <notes-list></notes-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as goalService from "../../../../../services/goalService";
Vue.use(VeeValidate);
import notesList from "../../../../participant-management/notes/notes-list";
import * as lineItemService from "../../../../../services/lineItemSevice";

export default {
  name: "goals",

  data: () => ({
    vm: {},
    valid: true,
    loading: false
  }),
  props: {
    msg: String
  },
  components: {
    "notes-list": notesList
  },
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentGoal() {
      return this.$store.getters.currentGoal;
    }
  },
  methods: {
        printform(){
      print()
    },
    init() {
      this.vm = {};
      if (this.currentGoal !== undefined && this.currentGoal !== null) {
        this.vm = this.currentGoal;
      }

      lineItemService
        .getById(this.currentCase.planDetails.lineItemDefinitionId)
        .then(result => {
          this.lineItemDefinition = result.data;
        });
    },

    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          let me = this;
          goalService
            .save(me.currentOrganisation.id, me.currentCase.id, me.vm)
            .then(ref => {
              if (ref != undefined) {
                me.vm.id = ref.id;
              }
              me.$store.commit("setCurrentGoal", me.vm);

              me.$store.dispatch("setToast", {
                message: "Event saved",
                color: "success"
              });
            })
            .catch(error => {
              console.error("Error saving event: ", error);
            });
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    next() {
      this.$emit("next-step");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

