<template>
  <v-container fluid>
    <v-row>
      <v-col class="mt-0 pt-0 pb-0" cols="12">
        <v-card>
          <v-card-actions>
            <v-icon @click="close">mdi-arrow-left-circle</v-icon>
            <v-spacer></v-spacer>
            <v-select
              v-model="deleted"
              :items="[{text: 'Active notes', value: false}, {text: 'Deleted notes', value: true}]"
              @change="toggleDeleted"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn @click="addAlert">Add note</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-data-table
              id="list-table"
              ref="datatable"
              :headers="currentHeaders"
              :items="notes"
              item-key="index"
              class="mr-2"
              v-if="!deleted"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td>{{ props.item.title }}</td>
                  <td style="max-width:400px">{{ props.item.details}}</td>
                  <td>{{ props.item.createdBy.displayName}}</td>
                  <td>{{moment(util.toDt(props.item.createdOn)).format("DD-MM-YYYY hh:mm") }}</td>
                  <td>{{ props.item.modifiedBy.displayName}}</td>

                  <td>{{ moment(util.toDt(props.item.modifiedOn)).format("DD-MM-YYYY hh:mm")}}</td>
                  <td class="justify-center layout px-0">
                    <v-icon small class="mr-2" @click="editItem(props.item)">mdi-pencil</v-icon>
                    <v-icon small @click="deleteItem(props.item)">mdi-delete</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>

            <v-data-table
              id="list-table"
              ref="datatable"
              :headers="deletedHeaders"
              :items="deletedNotes"
              item-key="index"
              class="mr-2"
              v-if="deleted"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td>{{ props.item.title }}</td>
                  <td style="max-width:400px">{{ props.item.details}}</td>
                  <td>{{ props.item.createdBy.displayName}}</td>
                  <td>{{moment(util.toDt(props.item.createdOn)).format("DD-MM-YYYY hh:mm") }}</td>

                  <td>{{moment(util.toDt(props.item.deletedOn)).format("DD-MM-YYYY hh:mm") }}</td>

                  <td>{{props.item.deletedBy==undefined ?"":props.item.deletedBy.displayName}}</td>
                  <td class="justify-center layout px-0">
                    <v-icon small @click="unDeleteItem(props.item)">mdi-undo-variant</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
    <br />
    <br />

    <v-dialog v-model="dialog" max-width="600px" persistent>
      <note-details :note="vm" v-on:note-added="noteAdded" v-on:note-closed="noteClosed"></note-details>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as notesService from "../../../services/generalNotesService";
import notesForm from "./notes-form";
Vue.use(VeeValidate);
import moment from "moment";
export default {
  name: "notes",
  components: {
    "note-details": notesForm
  },
  data: () => ({
    editedIndex: -1,
    vm: {},
    util: null,
    deleted: false,
    dialog: false,
    notes: [],
    deletedNotes: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ],
    currentHeaders: [
      { text: "Title", value: "title" },
      { text: "Description", value: "description" },
      { text: "created by", value: "created by" },
      { text: "Created on", value: "createdon" },
      { text: "Last modified by", value: "modifiedBy" },
      { text: "Modified on", value: "modifiedOn" },
      { text: "", value: "actions" }
    ],
    deletedHeaders: [
      { text: "Title", value: "title" },
      { text: "Description", value: "description" },
      { text: "created by", value: "created by" },
      { text: "Created on", value: "createdon" },
      { text: "Deleted On", value: "deletedOn" },
      { text: "Deleted By", value: "deletedBy" },

      { text: "", value: "actions" }
    ]
  }),
  mounted() {
    this.util = this.$utils;
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },

    init() {
      this.vm = {};
      notesService
        .list(this.currentOrganisation.id, this.currentCase.id)
        .then(result => {
          let notes = [];
          result.docs.map(doc => {
            const note = doc.data();
            note.id = doc.id;
            notes.push(note);
          });
          this.notes = notes;
        });
    },

    close() {
      this.$router.push("/participant-management/current-participant");
      setTimeout(() => {
        this.vm = {};
      }, 300);
    },
    editItem(item) {
      this.editedIndex = this.notes.indexOf(item);
      this.vm = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const me = this;
      const index = me.notes.indexOf(item);
      if (confirm("Are you sure you want to delete this item?")) {
        notesService
          .remove(
            me.currentOrganisation.id,
            me.currentCase.id,
            item.id,
            me.user
          )
          .then(result => {
            console.log(result);
            me.notes.splice(index, 1);
          });
      }
    },
    toggleDeleted() {
      //this.deleted = !this.deleted;
      if (this.deleted) {
        this.getDeleted();
      }
    },
    getDeleted() {
      notesService
        .listRemoved(this.currentOrganisation.id, this.currentCase.id)
        .then(result => {
          let notes = [];
          result.docs.map(doc => {
            const note = doc.data();
            note.id = doc.id;
            notes.push(note);
          });

          this.deletedNotes = notes.sort((ax, bx) => {
            if (ax.modifiedOn == undefined) {
              return 1;
            }
            if (bx.modifiedOn == undefined) {
              return -1;
            }
            let a = ax.modifiedOn.toDate();
            let b = bx.modifiedOn.toDate();
            return a > b ? -1 : a < b ? 1 : 0;
          });
        });
    },
    unDeleteItem(item) {
      const me = this;
      const index = me.deletedNotes.indexOf(item);

      notesService
        .unRemove(
          me.currentOrganisation.id,
          me.currentCase.id,

          item.id
        )
        .then(result => {
          console.log(result);
          me.notes.push(item);
          me.deletedNotes.splice(index, 1);
        });
    },
    addAlert() {
      this.vm = {};
      this.dialog = true;
    },
    noteAdded(data) {
      if (this.editedIndex > -1) {
        Object.assign(this.notes[this.editedIndex], data);
      } else {
        this.notes.push(data);
      }
      this.noteClosed();
      console.log(data);
    },
    noteClosed() {
      this.dialog = false;
      setTimeout(() => {
        this.vm = {};
        this.editedIndex = -1;
        this.$forceUpdate();
      }, 300);
    }
  }
};
</script>
